import React from "react"
import Banner from "./Banner/Banner";
import News from "./news";


function Home(){
    return(
       <div>
        <Banner />
        <News />

       </div>
        
    )
}

export default Home 